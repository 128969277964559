import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:""
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken;
    }
  },
  actions: {
    updateToken({ commit }, newToken) {
      commit('setToken', newToken);
    }
  },
  modules: {
  }
})
