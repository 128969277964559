import axios from "axios";
import router from "@/router";
// import { type } from "jquery";
//线上目前域名配置
 axios.defaults.baseURL = 'https://doadmin.17qiuxue.cn'
//线上
// axios.defaults.baseURL = "http://admin-api.17qiuxue.cn"
// axios.defaults.baseURL = "http://jzapi.xviq.cn";

// 配置全局的请求头
// axios.defaults.headers.common['Authorization'] = token
// 添加请求拦截器
// 请求拦截器:是在当前项目中所有网络请求即将发送出去之前会执行的一个函数,在这个函数中可以拿到发送请求携带的任何信息
// 包含的有请求参数、请求方法、请求头、请求路径
// 在这个请求拦截函数中必须return config
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = localStorage.getItem("token")||sessionStorage.getItem("token");
    // console.log(token)
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// 响应拦截:是当前项目中所有网络请求回来之前会执行的一个函数，这个函数中可以获取到所有的响应信息,
// 包含的有后端响应给前端的数据、响应头信息等
axios.interceptors.response.use(
  function (response) {
    // console.log(response,'response')
    // 如果状态码不是200做统一提示
    if (response.status !== 200) {
      // 使用模态框提示用户请求失败
      console.log("error");
      alert(response+'1111')
    } else {
      if (response.data.status != 200 && (response.data.err_code == 10001||response.data.err_code == 10002)) {
        //如果是H5跳登录页面  如果是微信 跳微信授权
        //判断是什么浏览器
        if (/MicroMessenger/.test(window.navigator.userAgent)) {
          console.log("微信")
          localStorage.setItem('isWechat',1)
        }  else {
          //alert('其他浏览器');
          console.log("其他")
          localStorage.setItem('isWechat',0)
        }
        let isWechat = localStorage.getItem('isWechat')
        if(isWechat==1){
          localStorage.removeItem('token')
          router.push({ path: "/wechatLogin" });
        }else{
          router.push({ path: "/login" });
        }
      } else {
        return response.data;
      }
    }
  },
  function (error) {
    // 对响应错误做点什么
    console.log(error, "error");
    alert(error+'222')
  }
);
//获取手机验证码
export function getCodes(params) {
  return axios.post(`/api/base/send_sms`, params);
}

//注册 登录
export function getRegister(params) {
  return axios.post(`/api/user/reg`, params);
}
//登录
export function getLogin(params) {
  return axios.post(`/api/user/login`, params);
}
//绑定
export function getBind(params) {
  return axios.post(`/api/user/bind_mobile`, params);
}
//获取图形验证信息
export function getImgCode() {
  return axios.post(`/api/base/verify`);
}
//验证图形验证信息
export function checkImgCode(verify_code) {
  return axios.post(`/api/base/verify_check`, { verify_code });
}
//获取协议内容
export function getAgreement() {
  return axios.post(`/api/base/agreement`);
}
//获取个人中心内容
export function getMine() {
  return axios.post(`/api/user/index`);
}
//获取我的项目列表
export function getMyProject(page) {
  return axios.post(`/api/user/donate_list`, { page: page });
}
//获取我发起的一起捐列表
export function getMyLaunch(page) {
  return axios.post(`/api/user/my_launch`, { page: page });
}
//获取学校项目列表
export function getAllProject(params) {
  return axios.post(`/api/school/item_lists`, params);
}
//获取项目详情
export function getProjectDetail(id) {
  return axios.post(`/api/item/index`, { item_id: id });
}
//获取项目爱心播报列表
export function getHeartList(id) {
  return axios.post(`/api/item/donate_new`, { item_id: id });
}
//获取项目捐赠列表
export function getProjectDonateList(params) {
  return axios.post(`/api/item/donate_list`, params);
}
//获取线下捐赠列表
export function getOffLineDonateList(params) {
  return axios.post(`/api/item/offline`, params);
}
//获取捐赠信息
export function getProjectDonateInfo(id) {
  return axios.post(`/api/item/donate`, { item_id: id });
}
//一起捐提交
export function sendTogetherDonate(params) {
  return axios.post(`/api/item/launch_together`, params);
}
//获取一起捐详情 即分享页面内容
export function getShareInfo(together_id) {
  return axios.post(`/api/item/together_detail`, { together_id });
}
//获取我的记录记录
export function getMyDonateList(page){
  return axios.post(`/api/user/my_donate_log`,{page:page});
}
//捐赠页面提交
export function subMitDonateForm(params){
  return axios.post(`/api/item/donate_do`,params);
}
//支付宝支付
export function getAlipayData(itemId,logId){
  return axios.post(`/api/alipay/pay`,{item_id:itemId,log_id:logId})
}
//微信支付
export function getWebchatPay(params){
  return axios.post(`/api/wechat/pay`,params)
}
//获取我的捐赠证书
export function getMyCertifi(params){
  return axios.post(`/api/item/cert`,params)
}
//微信授权登录
export function getWechatAuth(){
  return axios.post(`/api/wechat/auth`)
}
//微信授权获取信息
// export function getWechatInfo(code){
//   return axios.post(`/api/wechat/hide_auth`,{code:code})
// }
export function getWechatInfo(code){
  return axios.post(`/api/wechat/auth_getinfo`,{code:code})
}
//获取微信sdk参数
export function getWechatSdk(params){
  return axios.post(`/api/wechat/jssdk`,params)
}
//记录分享次数
export function getShareCount(params){
  return axios.post(`/api/item/shared`,params)
}

//一起捐列表
export function getTogetherList(params){
  return axios.post(`/api/item/together_list`,params)
}
//获取留言列表
export function getMessageList(params){
  return axios.post(`/api/item/message`,params)
}
//根据订单号获取项目信息
export function getOrderInfo(orderNo){
  return axios.post(`/api/item/pay_info`,{order_no:orderNo})
}