import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './media/media'
Vue.config.productionTip = false

import Vant from 'vant';
import 'vant/lib/index.css';

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // console.log(to,"to---route")
  // 保存之前的路径
  localStorage.setItem('fromRoute',from.fullPath)
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
    console.log(document.title)
  }
  next()
})
// console.log(window.navigator.userAgent)
//判断是什么浏览器
if (/MicroMessenger/.test(window.navigator.userAgent)) {
  console.log("微信")
  localStorage.setItem('isWechat',1)
}  else {
  //alert('其他浏览器');
  console.log("其他")
  localStorage.setItem('isWechat',0)
}